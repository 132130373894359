import React, { useState, useEffect, useMemo } from 'react';
import { 
  LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, ResponsiveContainer, ReferenceLine, Legend, 
  PieChart, Pie, Cell, BarChart, Bar, RadarChart, PolarGrid, PolarAngleAxis, PolarRadiusAxis, Radar, AreaChart, Area
} from 'recharts';
import './FloorPopup.css';
import Papa from 'papaparse';
import PlayArrowIcon from '@mui/icons-material/PlayArrow';
import PauseIcon from '@mui/icons-material/Pause';

const controlLimits = {
  danger: 80,
  warning: 60,
  caution: 40,
  attention: 20,
  lower: 0,
};

const COLORS = ['#8884d8', '#82ca9d', '#ffc658', '#ff8042', '#0088FE', '#00C49F', '#FFBB28'];

/** 
 * 각 컴포넌트(설비) 별 한글 이름 
 */
const componentNames = {
  FP: '급수펌프',
  MKD: '발전기',
  MAK: '기어박스',
  MAA: '고압터빈',
  MAV: '윤활 오일',
  MAB: '중압터빈',
  IOT: 'IoT Data'
};

/**
 * 각 컴포넌트(설비) 별 색상 
 */
const componentColors = {
  FP: '#8884d8',
  MKD: '#82ca9d',
  MAK: '#ffc658',
  MAA: '#ff8042',
  MAV: '#0088FE',
  MAB: '#00C49F',
  IOT: '#FFBB28'
};

// Invert componentNames for easier color lookup from the RBC data
const nameToKeyMap = {};
Object.entries(componentNames).forEach(([key, value]) => {
  nameToKeyMap[value] = key; // e.g. nameToKeyMap['급수펌프'] = 'FP'
});

// [ADDED] A helper function to determine the color based on the current T2 value
const getStateColor = (value) => {
  if (value >= controlLimits.danger) return 'red';
  if (value >= controlLimits.warning) return 'orange';
  if (value >= controlLimits.caution) return 'yellow';
  if (value >= controlLimits.attention) return 'green';
  return 'blue';
};

const FloorPopup = ({ floorNumber, onClose }) => {
  // Function to generate random index
  const generateRandomIndex = (dataLength) => {
    return Math.floor(Math.random() * dataLength);
  };

  // Function to generate random date between 2023.01.06 and 2023.02.23
  const generateRandomDate = () => {
    const start = new Date('2023-01-06');
    const end = new Date('2023-02-23');
    return new Date(start.getTime() + Math.random() * (end.getTime() - start.getTime()));
  };

  const [timeSeriesData, setTimeSeriesData] = useState([]);
  const [combinedT2Data, setCombinedT2Data] = useState([]);
  const [rbcData, setRbcData] = useState([]);
  const [rbcEachData, setRbcEachData] = useState([]);
  const [currentIndex, setCurrentIndex] = useState(0);
  const [randomStartIndex, setRandomStartIndex] = useState(0);
  const [isPaused, setIsPaused] = useState(false);
  const [pausedIndex, setPausedIndex] = useState(null);
  const [baseDate] = useState(() => generateRandomDate());

  // ----
  // 1) Load all CSV data on mount
  // ----
  useEffect(() => {
    Promise.all([
      new Promise(resolve => {
        Papa.parse('/csvs/T2_df.csv', {
          download: true,
          header: true,
          complete: (result) => resolve(result.data),
        });
      }),
      Promise.all(['MKD', 'MAV', 'MAK', 'MAB', 'MAA', 'IOT', 'FP'].map(file => 
        new Promise((resolve) => {
          Papa.parse(`/csvs/T2_df_${file}.csv`, {
            download: true,
            header: true,
            complete: (result) => resolve({ file, data: result.data }),
          });
        })
      )),
      new Promise(resolve => {
        Papa.parse('/csvs/rbc_df.csv', {
          download: true,
          header: true,
          complete: (result) => resolve(result.data),
        });
      }),
      new Promise(resolve => {
        Papa.parse('/csvs/RBC_df_each.csv', {
          download: true,
          header: true,
          complete: (result) => resolve(result.data),
        });
      })
    ]).then(([timeSeriesData, combinedT2Results, rbcData, rbcEachData]) => {
      setTimeSeriesData(timeSeriesData);
      
      const combinedData = combinedT2Results[0].data.map((row, i) => {
        const combined = { datetime: row.datetime };
        combinedT2Results.forEach(({ file, data }) => {
          if (data[i] && data[i].T2 !== undefined) {
            combined[file] = parseFloat(data[i].T2);
          }
        });
        return combined;
      });
      setCombinedT2Data(combinedData);
      
      setRbcData(rbcData);
      setRbcEachData(rbcEachData);

      // Set random start index based on the length of the data
      const minLength = Math.min(timeSeriesData.length, combinedData.length, rbcData.length);
      const randomIndex = generateRandomIndex(minLength);
      setRandomStartIndex(randomIndex);
      setCurrentIndex(randomIndex);
    });
  }, []);

  // ----
  // 2) Auto-increment currentIndex every 10s
  // ----
  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentIndex((prevIndex) => {
        const nextIndex = prevIndex + 1;
        const maxLength = Math.min(timeSeriesData.length, combinedT2Data.length, rbcData.length);
        return nextIndex >= maxLength ? randomStartIndex : nextIndex;
      });
    }, 10000);

    return () => clearInterval(interval);
  }, [timeSeriesData.length, combinedT2Data.length, rbcData.length, randomStartIndex]);

  // ----
  // 3) Pause/Resume handling
  // ----
  const handlePause = () => {
    setIsPaused(true);
    setPausedIndex(currentIndex);
  };

  const handleResume = () => {
    setIsPaused(false);
    setPausedIndex(null);
  };

  // ----
  // 4) Actual index to display in all charts
  // ----
  const displayIndex = isPaused ? pausedIndex : currentIndex;

  // ----
  // 5) Prepare data for "화재 개별 지수" (TimeSeries chart)
  // ----
  const displayTimeSeriesData = useMemo(() => {
    return timeSeriesData.slice(displayIndex, displayIndex + 10).map((row, index) => {
      const date = new Date(baseDate);
      date.setMinutes(date.getMinutes() + index * 10); // Add 10 minutes for each data point
      return {
        name: date.toLocaleTimeString('ko-KR', {
          hour: '2-digit',
          minute: '2-digit'
        }),
        value: parseFloat(row.T2) + 15,
      };
    });
  }, [timeSeriesData, displayIndex, baseDate]);

  // ----
  // 6) Prepare data for combined T2 chart
  // ----
  const displayCombinedT2Data = useMemo(() => {
    return combinedT2Data.slice(displayIndex, displayIndex + 10).map((row) => row);
  }, [combinedT2Data, displayIndex]);

  // ----
  // 7) Prepare data for RBC (Pie & RBC stacked line) 
  //    (Though we won't use RBC data for the radar chart now)
  // ----
  const displayRbcData = useMemo(() => {
    return rbcData.slice(displayIndex, displayIndex + 10).map((row) => {
      const total = Object.entries(row)
        .filter(([key]) => key !== 'datetime')
        .reduce((sum, [, value]) => sum + parseFloat(value), 0);

      const percentages = {};
      Object.entries(row).forEach(([key, value]) => {
        if (key !== 'datetime') {
          const componentKey = key.replace('RBC_', '');
          // Map RBC_xxx -> human-readable label (like '발전기')
          percentages[componentNames[componentKey] || componentKey] = (parseFloat(value) / total) * 100;
        }
      });

      return { ...percentages, datetime: row.datetime };
    });
  }, [rbcData, displayIndex]);

  // For the Pie chart breakdown (not used anymore, but left for reference)
  const breakdownData = useMemo(() => {
    if (displayRbcData.length === 0) return [];
    const lastUpdatedData = displayRbcData[displayRbcData.length - 1];
    return Object.entries(lastUpdatedData)
      .filter(([key]) => key !== 'datetime')
      .map(([key, value]) => ({
        name: key,
        value: value
      }));
  }, [displayRbcData]);

  // For the Bar chart (Top 10 components in RBC data)
  const displayRbcEachData = useMemo(() => {
    if (rbcEachData.length === 0 || displayIndex >= rbcEachData.length) return [];

    const currentData = rbcEachData[displayIndex];
    const sortedComponents = Object.entries(currentData)
      .filter(([key]) => key !== 'datetime')
      .sort(([, a], [, b]) => parseFloat(b) - parseFloat(a))
      .slice(0, 10)
      .map(([key, value]) => {
        const componentKey = Object.keys(componentNames).find(comp => key.includes(comp));
        const name = componentKey ? `${key} (${componentNames[componentKey]})` : key;
        const color = componentKey ? componentColors[componentKey] : '#8884d8';
        return {
          name,
          value: parseFloat(value),
          color
        };
      });

    return sortedComponents;
  }, [rbcEachData, displayIndex]);

  // For the Bar chart X-axis domain
  const xAxisDomain = useMemo(() => {
    if (displayRbcEachData.length === 0) return [0, 100];
    const maxValue = Math.max(...displayRbcEachData.map(item => item.value));
    return [0, Math.ceil(maxValue / 10) * 10]; // Round up to the nearest 10
  }, [displayRbcEachData]);

  // ----
  // 9) Chart click -> jump to clicked index
  // ----
  const handleChartClick = (data) => {
    if (data && data.activeLabel) {
      const newIndex = timeSeriesData.findIndex(row => row.datetime === data.activeLabel);
      if (newIndex !== -1) {
        setCurrentIndex(newIndex);
      }
    }
  };

  // ----
  // 10) Generate simulated radar data for 항목별 화재 개별 지수 영향요인
  // ----
  // 7 axis labels: TEMP, VIBRATION, PRESSURE, POSITION, RPM, LEVEL, OTHER
  const radarCategories = ['TEMP', 'VIBRATION', 'PRESSURE', 'POSITION', 'RPM', 'LEVEL', 'OTHER'];

  // A helper to generate random int [0..100]
  const getRandomValue = () => Math.floor(Math.random() * 101);

  // For the radar chart, we'll filter out IOT from the component lists
  const radarComponents = Object.keys(componentNames).filter(key => key !== 'IOT');

  // Prepare an array of 7 objects, each has category + values for each component
  const simulatedRadarData = useMemo(() => {
    // Get the latest RBC data point (rightmost value)
    const currentRbcData = displayRbcData[displayRbcData.length - 1] || {};
    
    return radarCategories.map((cat) => {
      const dataPoint = {
        category: cat,
      };
      // Scale each component's value according to its current RBC percentage
      radarComponents.forEach(componentKey => {
        const componentName = componentNames[componentKey];
        const scalingFactor = currentRbcData[componentName] ? currentRbcData[componentName] / 100 : 0;
        dataPoint[componentKey] = getRandomValue() * scalingFactor;
      });
      return dataPoint;
    });
  }, [displayRbcData, radarComponents, radarCategories]); 

  // [ADDED] Get the latest T2 value from displayTimeSeriesData and determine color
  const currentT2Value = displayTimeSeriesData.length
    ? displayTimeSeriesData[displayTimeSeriesData.length - 1].value
    : 0;
  const stateColor = getStateColor(currentT2Value);

  return (
    <div className="floor-popup-overlay">
      <div 
        className="floor-popup" 
        style={{ 
          border: `2px solid ${stateColor}`, 
          boxShadow: `0 0 10px ${stateColor}`
        }}
      >
        <div 
          className="popup-header" 
          style={{ 
            backgroundColor: `rgba(${stateColor === 'red' ? '255,0,0' : 
              stateColor === 'orange' ? '255,165,0' : 
              stateColor === 'yellow' ? '255,255,0' : 
              stateColor === 'green' ? '0,128,0' : 
              '0,0,255'}, 0.5)`
          }}
        >
          <h2>
            {floorNumber === 1 || floorNumber === 2
              ? `${floorNumber} 호기 화재 개별 지수`
              : '기타 공간 화재 개별 지수'}
          </h2>
          <div className="header-buttons">
            <button className="toggle-button" onClick={isPaused ? handleResume : handlePause}>
              {isPaused ? (
                <>
                  <PlayArrowIcon /> 실시간
                </>
              ) : (
                <>
                  <PauseIcon /> 일시 정지
                </>
              )}
            </button>
            <button className="toggle-button close-button" onClick={onClose}>
              닫기
            </button>
          </div>
        </div>

        <div className="chart-grid">
          {/* 1) 화재 개별 지수 (Time Series) */}
          <div className="chart-container">
            <h3>
              {floorNumber === 1 || floorNumber === 2
                ? '화재 개별 지수'
                : '기타 공간 화재 개별 지수'}
            </h3>
            <ResponsiveContainer width="100%" height={300}>
              <LineChart data={displayTimeSeriesData} onClick={handleChartClick}>
                <CartesianGrid vertical={true} horizontal={false} />
                <XAxis dataKey="name" />
                <YAxis domain={[0, 100]} />
                <ReferenceLine y={controlLimits.danger} label="심각" stroke="red" strokeDasharray="3 3" />
                <ReferenceLine y={controlLimits.warning} label="경계" stroke="orange" strokeDasharray="3 3" />
                <ReferenceLine y={controlLimits.caution} label="주의" stroke="yellow" strokeDasharray="3 3" />
                <ReferenceLine y={controlLimits.attention} label="관심" stroke="green" strokeDasharray="3 3" />
                <ReferenceLine y={controlLimits.lower} label="안정" stroke="blue" strokeDasharray="3 3" />
                <Tooltip />
                <Legend />
                <Line type="monotone" dataKey="value" stroke="#8884d8" activeDot={{ r: 8 }} />
              </LineChart>
            </ResponsiveContainer>
          </div>

          {/* 2) 항목별 화재 개별 지수 영향요인 (RadarChart with simulated data) */}
          <div className="chart-container">
            <h3>항목별 화재 개별 지수 영향요인</h3>

            {/* 
              // [RADAR SWEEP ADDED]
              Wrap the RadarChart in a div with className="radar-container"
              Then add the overlay <div className="radar-overlay" />
            */}
            <div className="radar-container"> 
              {/* This is the rotating overlay for the "sweep" effect */}
              <div 
                className="radar-overlay" 
                style={{
                  background: `conic-gradient(
                    ${stateColor === 'red' ? 'rgba(255,0,0,0.25)' :
                      stateColor === 'orange' ? 'rgba(255,165,0,0.25)' :
                      stateColor === 'yellow' ? 'rgba(255,255,0,0.25)' :
                      stateColor === 'green' ? 'rgba(0,128,0,0.25)' :
                      'rgba(0,0,255,0.25)'} 0deg,
                    transparent 30deg,
                    transparent 360deg
                  )`
                }}
              />

              <ResponsiveContainer width="100%" height={300}>
                <RadarChart data={simulatedRadarData}>
                  <PolarGrid />
                  <PolarAngleAxis dataKey="category" />
                  <PolarRadiusAxis angle={30} domain={[0, 100]} />
                  <Tooltip formatter={(value) => `${value}%`} />
                  <Legend />
                  {radarComponents.map((componentKey) => {
                    const color = componentColors[componentKey] || '#000000';
                    const radarName = componentNames[componentKey] || componentKey;
                    return (
                      <Radar
                        key={componentKey}
                        name={radarName}
                        dataKey={componentKey}
                        stroke={color}
                        fill={color}
                        fillOpacity={0.2}
                      />
                    );
                  })}
                </RadarChart>
              </ResponsiveContainer>
            </div>
          </div>

          {/* 3) 화재 개별 지수 영향요인 (STACKED TIME-SERIES AREA CHART) */}
          <div className="chart-container">
            <h3>시간별 화재 개별 지수 영향요인</h3>
            <ResponsiveContainer width="100%" height={300}>
              <AreaChart data={displayRbcData.slice(0, 10).map((row, index) => {
                const date = new Date(baseDate);
                date.setMinutes(date.getMinutes() + index * 10);
                return {
                  ...row,
                  name: date.toLocaleTimeString('ko-KR', {
                    hour: '2-digit',
                    minute: '2-digit'
                  })
                };
              })}>
                <CartesianGrid strokeDasharray="3 3" />
                <XAxis dataKey="name" />
                <YAxis 
                  domain={[0, 100]} 
                  tickFormatter={(val) => `${Math.round(val)}%`}
                  ticks={[0, 20, 40, 60, 80, 100]} 
                />
                <Tooltip formatter={(value) => `${Math.round(value)}%`} />
                <Legend />
                {displayRbcData.length > 0 &&
                  Object.keys(displayRbcData[0])
                    .filter((key) => key !== 'datetime')
                    .map((key) => {
                      const compKey = nameToKeyMap[key]; 
                      const areaColor = compKey ? componentColors[compKey] : '#999999';
                      return (
                        <Area
                          key={key}
                          type="monotone"
                          dataKey={key}
                          stackId="rbc"
                          stroke={areaColor}
                          fill={areaColor}
                        />
                      );
                    })
                }
              </AreaChart>
            </ResponsiveContainer>
          </div>

          {/* 4) 상위 10개 영향요인 구성요소 (BarChart) */}
          <div className="chart-container">
            <h3>상위 10개 영향요인 구성요소</h3>
            <ResponsiveContainer width="100%" height={350}>
              <BarChart 
                data={displayRbcEachData} 
                layout="vertical" 
                margin={{ left: 5, right: 30, top: 20, bottom: 20 }}
              >
                <CartesianGrid strokeDasharray="3 3" />
                <XAxis 
                  type="number" 
                  domain={xAxisDomain}
                  ticks={[0, ...Array(5).fill().map((_, i) => (i + 1) * (xAxisDomain[1] / 5))]}
                  tickFormatter={(value) => `${value.toFixed(1)}%`}
                />
                <YAxis 
                  dataKey="name" 
                  type="category" 
                  width={180}
                  tick={{ fontSize: 11 }}
                  interval={0}
                />
                <Tooltip formatter={(value) => `${value.toFixed(2)}%`} />
                <Legend />
                <Bar dataKey="value">
                  {displayRbcEachData.map((entry, index) => (
                    <Cell key={`cell-${index}`} fill={entry.color} />
                  ))}
                </Bar>
              </BarChart>
            </ResponsiveContainer>
          </div>
        </div>
      </div>
    </div>
  );
};

export default FloorPopup;
